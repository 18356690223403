import React from 'react';
import './App.css'; // Add any global styles here
import RSVPForm from './components/RSVPForm'; // Import the RSVP Form
import OurStory from './components/OurStory';
import HeroSection from './styles/HeroSection.css';

const App = () => {

  const scrollToRSVP = () => {
    const rsvpSection = document.getElementById("rsvp-section");
    if (rsvpSection) {
      rsvpSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Morgan And Jordan</h1>
          <h2 className="hero-subheader">June 7th, 2025</h2>
          <button className="hero-button" onClick={scrollToRSVP}>
            RSVP Now
          </button>
        </div>
      </section>

      <OurStory />

      {/* RSVP Section */}
      <section id="rsvp-section">
        <RSVPForm />
      </section>
    </div>
  );
};

export default App;
