import React, { useState, useEffect } from 'react';

const Countdown = () => {
  const calculateTimeLeft = () => {
    const weddingDate = new Date('2025-06-07T00:00:00');
    const currentDate = new Date();
    const difference = weddingDate - currentDate;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <div className="countdown">
      <h2>Countdown to Our Wedding Day</h2>
      <div className="time-left">
        {timeLeft.days !== undefined ? (
          <div className="time-boxes">
            <div className="time-box">
              <span>{timeLeft.days}</span>
              <p>Days</p>
            </div>
            <div className="time-box">
              <span>{timeLeft.hours}</span>
              <p>Hours</p>
            </div>
            <div className="time-box">
              <span>{timeLeft.minutes}</span>
              <p>Minutes</p>
            </div>
            <div className="time-box">
              <span>{timeLeft.seconds}</span>
              <p>Seconds</p>
            </div>
          </div>
        ) : (
          <span>The big day has arrived!</span>
        )}
      </div>
    </div>
  );
};

export default Countdown;
