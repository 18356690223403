const guestList = [
    "Morgan Cole",
    "Jordan Shearer",
    "Jennifer Shearer",
    "Scott Shearer",
    "Alexis Shearer",
    "Matt Johns",
    "Grandma Whaley",
    "Grandpa Whaley",
    "Vanessa Painter",
    "Mike Painter",
    "Lucas Painter",
    "Ariana Painter",
    "Lori Cole",
    "Travis Cole",
    "Kayla Cole",
    "Paige Cole",
    "Chloe Cole",
    "Camden Dougherty",
    "Brock Cole",
    "Brock Cole Plus One",
    "Bailey Cole",
    "Grandma Cole",
    "Grandpa Cole",
    "Grandpa Schafer",
    "Grandma Schafer",
    "Amy Battles",
    "Chip Battles",
    "Leighton Battles",
    "Samuel Battles",
    "Drew Battles",
    "Carrie Robinson",
    "Nathan Robinson",
    "Charles Robinson",
    "Ellie Robinson",
    "Katie Busch",
    "Mitch Busch",
    "Mackenzie Scott",
    "Mackenzie Scott Plus One",
    "Olivia Scott",
    "Mark Scott",
    "Anderson Scott",
    "Julia Magliocca",
    "Ben Cutrer",
    "Natalie Fleming",
    "Cole Lovett",
    "Nikki Sparks",
    "Ethan Sparks",
    "Lauren Peterson",
    "James Nedrich",
    "Amber Hall",
    "Ashley Trevis",
    "Marcus Trevis",
    "Grace Rhoade",
    "Kenzy Beckett",
    "Aiamyia Dudley",
    "Jenna Razavi",
    "Tobias Jones",
    "Alayna Cuevas",
    "Shari Soza",
    "Joel Soza",
    "Dalia Soza",
    "Lisa Smith",
    "Jamie Smith",
    "Keaton Smith",
    "Ayden Smith",
    "Leslie Barwick",
    "Dave Barwick",
    "Courtney Barwick",
    "Coach Fresenko",
    "Deana Fresenko",
    "Emily Fresenko",
    "Emily Fresenko Plus One",
    "Maureen Radalia",
    "Dan Radalia",
    "Rhonda Mcquilken",
    "Kerry Swigert",
    "Katie Schoessel",
    "Jared Mathie",
    "Maggie Galentine",
    "Grandma Galentine",
    "Jackson Kandel",
    "Brook Eucker",
    "Jake Eucker",
    "Kayla Wrasman",
    "Sierra Aral",
    "Sierra Aral Plus one",
    "Brenda Toney",
    "Brenda Toney husband",
    "Carly Hall",
    "Brynn Guist",
    "Brynn Guist plus One",
    "Amy Wagner",
    "Lenny Wagner",
    "Kathy Lightfoot",
    "Becky Degeorge",
    "Rick Degeorge",
    "Jake Lininger",
    "Jake Lininger Plus one",
    "Alyssa Deeds",
    "Ethan Deeds",
    "John Schoessel",
    "Melissa Schoessel",
    "Maverick Pugh",
    "Todd Pugh",
    "Ana Pugh",
    "Lindsay Pugh",
    "Liz Pugh",
    "Uncle Pugh",
    "Uncle Pugh plus one",
    "Nick Eniex",
    "Mark Joseph",
    "Mark Joseph Plus One",
    "Cami Nau",
    "Austin Cummings",
    "Austin Cummings Plus one",
    "Lisa Cummings",
    "Mike Cummings",
    "Savannah Yacapraro",
    "Vito Yacapraro",
    "Connor Wendt",
    "Connor Wendt Plus one",
    "Travis Pape",
    "Travis Pape Plus one",
    "Tom Redd",
    "Melissa Redd",
    "Cole Redd",
    "Karah Redd",
    "Logan Redd",
    "Sophie Redd",
    "Dave Wagner",
    "Tricia Wagner",
    "Rachel Wagner",
    "Rachel Wagner plus one",
    "Jackson Wagner",
    "Rick Johnston",
    "Amber Johnston",
    "Courtney Dunn",
    "Courtney Dunn Plus one",
    "Lance Galentine",
    "Lisa Galentine",
    "Cassidy Kiko",
    "Cassidy Kiko plus one",
    "Kayla Gibson",
    "Kayla Gibson plus one",
    "Diana Pukys",
    "Diana Pukys Plus one",
    "David Roger",
    "Tiffany Rogers",
    "Carrie Leazer",
    "Mark Milne",
    "Mark Mline Plus one",
    "Jacob Jones",
    "Jacob Jones Plus one",
    "Stacy Gregorson",
    "Stacy Gregorson Plus one",
    "Chase Walkup",
    "Chase Walkup Plus one",
    "Megan Coon",
    "Megan Coon plus one",
    "Mackenzie Doll",
    "Mackenzie Doll Plus One",
    "Jim Funderwhite",
    "Jim Funderwhite Plus One",
    "Tom Nau",
    "Tom Nau Plus one",
    "Maggie (Scott’s sister)"
];

export default guestList;