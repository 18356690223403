import React, { useState, useEffect } from 'react';
import '../styles/RSVPForm.css';
import guestList from './guestList.js';

const RSVPForm = () => {
  const [isAttending, setIsAttending] = useState(false);
  const [guestCount, setGuestCount] = useState(1);
  const [guests, setGuests] = useState([{ name: "", meal: "" }]);
  const [searchTerm, setSearchTerm] = useState(""); // To store the search term
  const [filteredGuests, setFilteredGuests] = useState(guestList); // To store the filtered guest list
  const [keyboardHeight, setKeyboardHeight] = useState(0); // To store keyboard height
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeGuestIndex, setActiveGuestIndex] = useState(null); // Track active input
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(""); // New message state


  useEffect(() => {
    // Listen for changes in the viewport when the keyboard is displayed
    const handleViewportChange = () => {
      if (window.visualViewport) {
        setViewportHeight(window.visualViewport.height);
      } else {
        setViewportHeight(window.innerHeight); // Fallback if visualViewport is not available
      }
    };

    window.visualViewport?.addEventListener('resize', handleViewportChange);

    return () => {
      window.visualViewport?.removeEventListener('resize', handleViewportChange);
    };
  }, []);

  const handleResize = () => {
    // When the keyboard is visible, the innerHeight will be smaller
    if (window.innerWidth <= 768) {
      const currentHeight = window.innerHeight;
      const screenHeight = window.screen.height;

      if (currentHeight < screenHeight * 0.7) {
        // Estimate the keyboard height
        const kbHeight = screenHeight - currentHeight;
        setKeyboardHeight(kbHeight);
      } else {
        setKeyboardHeight(0); // Reset when keyboard is hidden
      }
    }
  };

  const handleAttendingChange = (event) => {
    setIsAttending(event.target.checked);
  };

  const handleSearchFocus = (event, index) => {
    const inputField = event.target;
    setActiveGuestIndex(index); // Track which input is focused
  
    const dropdown = document.querySelector('.guest-dropdown');
    
    if (dropdown && window.innerWidth > 768 && window.innerWidth <= 1024) {
      // Special adjustment for iPad landscape
      inputField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      dropdown.style.top = `${inputField.getBoundingClientRect().bottom + window.scrollY}px`;
    } else if (dropdown && window.innerWidth <= 768) {
      // Mobile adjustments
      inputField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  

  // Handle guest selection from filtered list
  const handleGuestSelect = (name, index) => {
    const updatedGuests = [...guests];
    updatedGuests[index] = { ...updatedGuests[index], name };
    setGuests(updatedGuests);
    setSearchTerm(""); // Clear the search term after selection
  };

  const handleSearchChange = (event, index) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filtered = guestList.filter((guest) =>
      guest.toLowerCase().includes(value.toLowerCase())
    );

    const uniqueFiltered = [...new Set(filtered)];
    setFilteredGuests(uniqueFiltered);

    const updatedGuests = [...guests];
    updatedGuests[index] = { ...updatedGuests[index], name: value };
    setGuests(updatedGuests);

    const inputField = event.target;
    const dropdown = document.querySelector('.guest-dropdown');

    // Ensure dropdown is adjusted when typing, specifically for iPad landscape
    if (dropdown && window.innerWidth > 768 && window.innerWidth <= 1024) {
      dropdown.style.top = `${inputField.getBoundingClientRect().bottom + window.scrollY}px`;
    } else if (dropdown && window.innerWidth <= 768) {
      inputField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleGuestCountChange = (event) => {
    setGuestCount(event.target.value);
  };

  const handleGuestCountBlur = () => {
    let count = parseInt(guestCount, 10);
  
    if (isNaN(count) || count < 1) {
      count = 1;
    }
  
    if (count > 10) {
      count = 10; // Limit guest count to 10
    }
  
    setGuestCount(count);
  
    const updatedGuests = [...guests];
    if (count > guests.length) {
      // Add empty guest entries if the count increases, but not more than 10
      for (let i = guests.length; i < count; i++) {
        if (i < 10) { // Ensure it doesn't exceed 10
          updatedGuests.push({ name: "", meal: "" });
        }
      }
    } else {
      updatedGuests.length = count; // Trim the guest list if the count decreases
    }
    setGuests(updatedGuests);
  };

  const handleGuestChange = (index, event) => {
    const updatedGuests = guests.map((guest, i) =>
      i === index ? { ...guest, [event.target.name]: event.target.value } : guest
    );
    setGuests(updatedGuests);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();  // Prevent default form submission behavior
  
    // Clear any previous messages
    setErrorMessage("");
    setSuccessMessage("");
    
    // Check for invalid guests and missing meal preferences
    const invalidGuests = guests.filter(guest => !guestList.includes(guest.name));
    const noMealPreference = guests.filter(guest => guest.meal === "");
  
    if (invalidGuests.length > 0) {
      setErrorMessage("Some guests are not on the guest list. Please verify the names.");
      return; // Stop the submission
    }
  
    if (noMealPreference.length > 0) {
      setErrorMessage("Please select a meal preference for all guests.");
      return; // Stop the submission
    }
  
    // Set loading state to true
    setLoading(true);
  
    try {
      // Send the data to the backend
      const response = await fetch('https://rsvpsite-gv12.onrender.com/api/rsvp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isAttending,
          guests,
          message,
        }),
      });
  
      const rsvpData = {
        isAttending,
        guests,
        message
      }
      console.log(rsvpData)

      if (response.ok) {
        // If the backend responds with success, show the success message
        setSuccessMessage("Thank you! Your RSVP has been submitted successfully.");
      } else {
        // If there's an issue with the backend, show an error message
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'There was an error submitting your RSVP.');
      }
    } catch (error) {
      // Handle network or unexpected errors
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      // Set loading to false after the request completes
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent form submission
      event.target.blur();  // Remove focus from the input
    }
  };

  return (
    <form className="rsvp-form" onSubmit={handleSubmit}>
      {/* RSVP Section Title */}
      <h2 className="rsvp-title">Kindly RSVP</h2>

      <div className="rsvp-date">
        June 7th, 2025
      </div>

      <div className="form-group checkbox-group centered">
        <label className="custom-checkbox">
          <input
            type="checkbox"
            name="attending"
            checked={isAttending}
            onChange={handleAttendingChange}
          />
          <span className="custom-checkmark">
            {/* Burst animation inside the checkbox */}
            {isAttending && (
              <div className="burst-animation">
                {[...Array(6)].map((_, i) => (
                  <span
                    key={i}
                    className={`burst ${
                      Math.random() > 0.5 ? 'heart' : 'star'
                    } direction-${i + 1}`}
                  ></span>
                ))}
              </div>
            )}
          </span>
          Will you be joining us?
        </label>
      </div>

     {/* Show additional fields if attending */}
     {isAttending && (
        <>
          {/* Guest Count Field */}
          <div className="form-group">
            <label htmlFor="guestCount">Number of Guests</label>
            <input
              type="number"
              id="guestCount"
              name="guestCount"
              min="1"
              max="10"
              value={guestCount}
              onChange={handleGuestCountChange}
              onKeyPress={handleKeyPress}
              onBlur={handleGuestCountBlur}
              required
            />
          </div>

          {guests.map((guest, index) => (
            <div key={index} className="form-group guest-details">
              <h4>Guest {index + 1}</h4>

              {/* Guest search container */}
              <div className="guest-search-container">
                {/* Searchable Guest Name Input */}
                <input
                  type="text"
                  name="name"
                  placeholder="Search guest name"
                  value={guest.name}
                  onChange={(e) => handleSearchChange(e, index)}
                  onFocus={(e) => handleSearchFocus(e, index)}  // Pass the index of the active input
                  onKeyPress={handleKeyPress}  // Prevent form submission on Enter
                />

                {/* Display filtered guest names only for the active input */}
                {activeGuestIndex === index && searchTerm && (
                  <ul className="guest-dropdown">
                    {filteredGuests.map((guestName) => (
                      <li
                        key={guestName}
                        onClick={() => handleGuestSelect(guestName, index)}
                      >
                        {guestName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Meal preference */}
              <select
                name="meal"
                value={guest.meal}
                onChange={(e) => handleGuestChange(index, e)}
              >
                <option value="">Select Meal Preference</option>
                <option value="vegetarian">Vegetarian</option>
                <option value="non-vegetarian">Non-Vegetarian</option>
              </select>
            </div>
          ))}
        </>
      )}

      {/* Message field */}
      <div className="form-group">
        <label htmlFor="message">Leave a Fun Message!</label>
        <textarea
          id="message"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)} // Update message state
          placeholder="Write something fun..."
        />
      </div>

      {/* Submit Button */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {loading && <p className="loading-message">Submitting RSVP...</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <button type="submit" className="submit-btn" disabled={loading}>
        {loading ? 'Submitting...' : 'Submit RSVP'}
      </button>
    </form>
  );
};


export default RSVPForm;
